import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import request from 'helpers/request';
import { connect } from 'react-redux';
import { logInAsUser, setCurrentUserDetails } from 'redux/actions';
import { getLanguageSchema } from 'dashboard-commons/languages';

import { Table, Input, Button, Tabs, Tag, Spin, Select } from 'antd';
import 'antd/dist/antd.css';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { timeout } from 'utils/functions';

const Search = Input.Search;
const { TabPane } = Tabs;

const { Option } = Select;

function List_NEW(props) {
	const [total, setTotal] = useState(0);
	const [users, setUsers] = useState([]);
	const [search, setSearch] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [syncLoading, setSyncLoading] = useState(false);
	const [syncAccounts, setSyncAccounts] = useState([]);
	const [stock, setStock] = useState('');
	const [disabled, setDisabled] = useState(true);
	const [stocks, setStocks] = useState([]);
	const [limit, setLimit] = useState(20);

	let { languageSchema, user } = props;

	useEffect(() => {
		fetchUsers();
		fetchStocks();
		fetchSync();
	}, []);

	const columns = [{
		title: 'Email',
		dataIndex: 'email',
	},
	{
		title: 'Name',
		dataIndex: 'name',
		render: (text, record) => (
			<span>{(!_.isEmpty(record.firstName) ? record.firstName : '') + ' ' + (!_.isEmpty(record.lastName) ? record.lastName : '')}</span>
		),
	},
	{
		title: 'Account type',
		dataIndex: 'accountType',
	},
	{
		title: 'Phone',
		dataIndex: 'phone',
	},
	{
		title: 'Role',
		dataIndex: 'roleId',
		render: (text, record) => (
			<span>{record.role}</span>
		),
	},
	{
		title: '',
		dataIndex: '_id',
		render: (text, record) => (
			<>
				<Link to={`/users/${record._id}/contracts`}
					className="ant-tag ant-tag-cyan" >
					{languageSchema.User.contracts}
				</Link>
				{ ' '}
				<Link to={`/users/${record._id}`} className="ant-tag ant-tag-blue">
					{languageSchema.Buttons.edit}
				</Link>
				{' '}
				<Button size='small' type="primary" onClick={() => becomeOrdinaryUser(record._id)}>{languageSchema.User.loginAsUser}</Button>
			</>
		),
	},
	];

	const becomeOrdinaryUser = async (userId) => {
		await props.logInAsUser(userId);
		await props.setCurrentUserDetails();
		props.history.push('/my-boxes');
	};

	const handlePagination = (page, size) => {
		fetchUsers(page, search, size);
		setCurrentPage(page);
		setLimit(size);
	};

	const onSearch = value => {
		setSearch(value);
		fetchUsers(1, value, limit);
		setCurrentPage(1);
	};

	const fetchUsers = async (page = 1, search = '', limit = 20) => {
		setLoading(true);

		const result = await request('GET', `users?search=${search}&page=${page}&limit=${limit}`);

		let users = result.users;
		let total = result.total;

		users = users.map(user => ({ ...user, key: user._id }));

		await timeout(2000);

		setUsers(users);
		setTotal(total);
		setLoading(false);
	};

	const fetchSync = async () => {
		setSyncLoading(true);

		const result = await request('GET', 'sync/top10');

		await timeout(2000);
		setSyncAccounts(result);
		setSyncLoading(false);
	};

	const fetchStocks = async () => {
		const result = await request('GET', 'stocks');
		setStocks(result);
	};

	const onSelectChange = (value) => {
		setStock(value);
		setDisabled(false);
	};

	const synchronize = async () => {
		setSyncLoading(true);

		await request('POST', 'sync', { email: user.me.email, stockId_sm: stock });

		fetchSync();
	};

	const getStatus = (status) => {
		return status ? 'orange' : 'cyan';
	};

	return (
		<>
			<div className='card'>
				<Tabs type="card">
					<TabPane tab="Accounts" key="1">
						<Row>
							<Col>
								<Link to="/users/add" className="btn btn-success mr-2">
									{languageSchema.User.addAccount}
								</Link>
							</Col>
							<Col md="auto"></Col>
							<Col xs lg="2">
								<Search className = 'mt-2' placeholder="Email search" onSearch={onSearch} allowClear/>
							</Col>
						</Row>

						<Table className = "table-responsive pt-3"
							loading={loading}
							columns={columns}
							dataSource={users}
							pagination={{
								total,
								pageSize: limit,
								current: currentPage,
								onChange: (page, size) => {
									handlePagination(page, size);
								},
							}}>
						</Table>
					</TabPane>
					<TabPane tab="Synchronizations" key="2">
						<div className="row">
							<div className="col-md-8">
								<Spin spinning={syncLoading}>
									<div className="boxer">
										<div className="box-row">
											<div className="box">User who synced</div>
											<div className="box">Sync Date</div>
											<div className="box">Synced accounts / count(*)</div>
											<div className="box text-right pr-4">Sync Status</div>
										</div>
										{
											syncAccounts.map((sync, index) => (
												<div className="box-row" key={ index}>
													<div className="box">{sync.email}<br /></div>
													<div className="box">{sync.createdAt}</div>
													<div className="box ebay"> {sync.syncedAccounts} <br /> <small className="text-black-50">accounts have been synced</small></div>

													<div className="box google text-right"> 	<Tag color={getStatus(sync.isActive)}>{sync.isActive? 'pending': 'resolved'}</Tag> </div>

												</div>

											))}
									</div>
								</Spin>
							</div>
							<div className="col-md-4">
								<div className="well text-center" style={{ backgroundColor: '#ffffff' }}>
									<div className='row g-3'>
										<div className='col-sm-5'>

											<Select
												style={{ width: '100%' }}
												placeholder = {languageSchema.Synchronization.selector}
												optionFilterProp="children"
												onChange={onSelectChange}
												filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
											>
												{stocks && stocks.map(stock =>
													<Option value={stock.stockId_sm} key={stock.stockId_sm }>{stock.name }</Option>
												)}
											</Select>
										</div>
										<div className='col-sm-7'>
											<Button className="btn-info btn-md pull-center" onClick={synchronize} disabled>{languageSchema.Synchronization.button}</Button>
										</div>
									</div>
									
								</div>
							</div>
						</div>
					</TabPane>
				</Tabs>

			</div>
		</>
	);
}

let mapStateToProps = ({ common, auth }) => {
	return {
		languageSchema: getLanguageSchema(common.language),
		user: auth,
	};
};

let mapDispatchToProps = {
	logInAsUser,
	setCurrentUserDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(List_NEW);
